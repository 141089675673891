export const languages = {
    sv: 'Swedish',
    da: 'Danish',
    no: 'Norwegian',
    pl: 'Polish',
    is: 'Icelandic',
    en: 'English',
    de: 'German',
    fr: 'French',
    lt: 'Lithuanian',
    ru: 'Russian',
    it: 'Italian',
    es: 'Spanish',
    nl: 'Dutch',
    pt: 'Portuguese',
    tr: 'Turkish',
    zh: 'Chinese',
    fi: 'Finnish',
    el: 'Greek',
    hu: 'Hungarian',
    ja: 'Japanese',
    ua: 'Ukrainian',
    sk: 'Slovak',
    ro: 'Romanian',
    cs: 'Czech',
    lv: 'Latvian',
    ee: 'Estonian',
    cz: 'Czech',
    bg: 'Bulgarian',
};

export const textTypes = {
    description: 'Description',
    application: 'Application',
    product_use: 'Product Use',
    treatment: 'Treatment',
    substrate: 'Substrate',
    h_sentence: 'Health Safety Sentence',
    clp_pictogram: 'CLP Pictogram',
    expected_result: 'Expected Result',
    please_note: 'Please Note',
    supplementary_info: 'Supplementary Information',
    environmental_information: 'Environmental Information',
    dilution: 'Dilution',
    storage: 'Storage',
    usp: 'Unique Selling Points',
};

export const fileTypes = {
    // Both wetgoods and drygoods
    sds: 'SDS - Safety Data Sheet',
    tds: 'TDS - Technical Data Sheet',

    // Wetgoods only
    dgnbdk: 'DGNB-DK',
    fdv: 'FDV - Operation and Maintenance',
    breeamnor: 'BREEAM NOR - HEA02',
    co2md: 'CO₂ Manufacture declaration',
    epd: 'EPD - Environmental Product Declaration',
    'pl-h-cert': 'ATEST Higieniczny - Hygienic Certificate',
    bvd: 'BVD - Byggvarudeklaration',

    // Drygoods only
    dop: 'DoP - Declaration of Performance',
    fsc: 'FSC',
    m1: 'M1 - Emission Classification of Building Materials',
    //Removed
    /*
    dbb: 'DBB - Detergent Data Sheet',
    ral: 'RAL',
    doc: 'DoC - Declaration of Compliance',

    */
};
